

.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    border-radius: 8px;
    width: 620px;
    background-color: #FFFFFF;

    z-index: 1000;
}

.modal-container-mo {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 998;
}

.modal-layout-mo {
    width: 100%;
    z-index: 1000;
    background-color: #FFFFFF;
    height: 100vh; /* Full screen height minus 64px */
    max-height: 100vh; /* Prevents exceeding the calculated height */
    overflow: hidden;
    border-radius: 8px !important;
}

.modal-dimmed-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.53);
    z-index: 999;
}

.modal-header {
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 0 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.modal-header .modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.modal-header .modal-exit-button {
    padding: 6px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-body {
    overflow-y: auto;
    max-height: 828px;
}

.modal-body-mo {
    overflow-y: auto;
    height: calc(100% - 72px);
}

.modal-image-wrapper {
    width: 100%;
}

.modal-image {
    width: 100%;
}

.modal-bottom {
    padding: 16px 24px 32px 24px;
    border-top: 1px solid #DDDDDD;
    background-color: #F5F5F5;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    display: flex;
    flex-direction: column;
    gap: 4px;
}

.bottom-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.bottom-description-list {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.bottom-description {
    position: relative;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #333333;
}

.bottom-description::before {
    position: absolute;
    top: 0px;
    left: -12px;
    content: "·";
    font-size: 16px;
    line-height: 20px;
    margin-right: 4px;
    font-weight: bold;
}

