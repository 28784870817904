

.subscription-intro-container {
    display: flex;
    justify-content: center;
    padding: 64px 0;
}

.subscription-img {
    width: 840px;
}

.subscription-intro-container-mo {
    display: flex;
    justify-content: center;
    padding: 64px 0;
}

.subscription-img-mo {
    width: 312px;
}