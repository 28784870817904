
.review-banner-container {
    padding: 64px 0 64px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #FAFAFA;
}

.review-banner-layout {
    max-width: 842px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.review-banner-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.review-banner-top-left {
    display: flex;
    gap: 8px;
    align-items: center;
}

.review-banner-top-right {
    display: flex;
    align-items: center;
}

.swiper-button {
    background-color: #E1E1E1;
    border-radius: 48px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.swiper-button.right {
    transform: rotate(180deg);
}

.swiper-page-number-layout {
    padding: 0 9.5px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.swiper-page-number.current {
    font-weight: 700;
    font-size: 16px;
    line-height: 19.2px;
    color: #FF5A00;
    width: 24px;
    text-align: center;
}

.swiper-page-number.whole {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    color: #111111;
    width: 24px;
    text-align: center;
}

.logo-image {
    width: 204px;
    height: 26px;
}

.review-banner-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
}

.review-swiper > div, .review-swiper-mo > div {
    display: flex !important;
}

.review-swiper-mo {
    width: calc(100vw - 48px);
}

.review-swiper-slide, .review-swiper-slide-mo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
    line-height: 0;
}

.review-banner-img {
    width: 842px;
}

.review-banner-img-mo {
    width: calc(100vw - 48px);
}

.review-banner-container-mo {
    padding: 64px 0;
    background-color: #FAFAFA;
    width: 100%;
    display: flex;
    justify-content: center;
}

.review-banner-layout-mo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 312px;
}

.review-banner-top-mo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.logo-img-mo {
    width: 214px;
}

.review-banner-title-mo {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
}

.review-banner-body-mo {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.review-banner-pagination {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.swiper-button-mo {
    width: 48px;
    height: 48px;
    border-radius: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E1E1E1EE;
}

.swiper-button-mo.right {
    transform: rotate(180deg);
}

.swiper-page-number-layout-mo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.swiper-page-number-mo.current {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FF5A00;
}

.swiper-page-number-mo.whole {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #111111;
}

.slash {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.4px;
}

.slash-mo {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}





