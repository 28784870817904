/* For PC */
.benefit {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
}

.benefit-img {
    margin: 64px 0;
    width: 840px;
}

.moo-moo-heart-img {
    width: 140px;
    height: 140px;
    position: absolute;
    bottom: 0;
    left: 50%; /* 부모 요소의 가로 중심 */
    transform: translateX(-50%) translateX(510px);
}

/* For Mobile */
.benefit-mo {
    display: flex;
    justify-content: center;
    width: 100%;
}

.benefit-img-mo {
    width: 312px;
    margin: 64px 0;
}