th {
    font-weight: 500 !important;
    padding-bottom: 8px !important;
    font-size: 16px;
    line-height: 28px;
}

input::placeholder, textarea::placeholder {
    color: #00000061 !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    font-weight: 400;
    opacity: 1;
}

input, textarea {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
}

.onlineRequest {
    display: flex;
    flex-direction: column;
    align-items: center;

    width:100%;
    height: auto;
    border: 0px;

    background: #FAFAFA;
}
.onlineRequestMo {
    width:100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FAFAFA;
}
.titleDiv {
    position: relative;
    width: 360px;
    height: auto;
    margin-top: 64px;
    margin-bottom: 48px;
}
.titleDivMo {
    width: 312px;
    margin-top: 60px;

}
.info {
    font-family: Noto Sans KR;
    font-style: normal;
    font-size: 15px;
    line-height: 50px;
    color: #000000;
    text-align: center;
}
.info-mo {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 8px;
    width: 80%;

    font-family: Noto Sans KR;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    text-align: center;

    align-items: center;
}
.optionMealType, .optionPersonnelType {
    margin-top: 24px;
    margin-right: 20px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */


    letter-spacing: -0.4px;
    color: #000000;
}
.optionUnitBudget, .optionShipType {
    margin-right: 16px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */

    letter-spacing: -0.4px;
    color: #000000;
}
.optionRcptDays {
    margin-right: 18px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */

    align-items: center;
    letter-spacing: -0.4px;
    color: #000000;
}
.optionSubscribeTerms {
    margin-right: 17px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */

    color: #000000;
}

.table-div {
    margin-top: 44px;
    width: 100%;
}
.table-thMo {
    text-align: left;
    padding: 0 16px 0 16px;
}
.thOptionMo {
    padding: 0 16px 0 16px;
}
.thOptionMo2 {
    padding: 0 4px 0 16px;
}
.thOption3 {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    align-items: flex-end;
    text-align: right;
    color: rgba(0, 0, 0, 0.5);
}
.thOptionMo3 {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    align-items: flex-end;
    text-align: right;
    padding: 0 16px 0 16px;
    color: rgba(0, 0, 0, 0.5);
}
.optionsTdMo {
    width: 100%;
    padding: 8px 16px 24px 16px;
}
textarea::placeholder {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: -0.4px;
    color: rgba(0, 0, 0, 0.38);
}
.personalCheckDiv {
    width: 100%;
    padding: 0 16px 0 16px;
}
.personalCheckLabel {
    width: 100%;
    margin-left: 8px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    letter-spacing: -0.4px;
    color: #000000;
    text-align: left;
}
.submitButton {
    border: 1px solid #FF5A00;
    background: #FF5A00;
    border-radius: 8px;
}
.submitButtonDisabled {

    border: 1px solid #9E9E9E;
    background-color: #9E9E9E;
    border-radius: 8px;
}

.mobileOptionTable{
    width: 100%;
    padding: 16px 16px 0 16px;
}
.mobileOptionTable2 {
    width: 100%;
    padding: 16px 16px 16px 16px;
}
