.faq, .faqMo {
    display: flex;
    flex-direction: column;
    align-items: center;

    width:100%;

}
.faqImageDiv {
    position: relative;
    margin-top: 64px;
    margin-bottom: 32px;

    display: flex;
    gap: 8px;
    align-items: center;
}

.faq-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 4px;
}

.faq-title-mo {
    width: 312px;

}

.image-moo-moo {
    position: absolute;
    width: 155px;
    top: -50px;
    right: -220px;
    z-index: 10;
}

.image-moo-moo-mo {
    position: absolute;
    width: 77.5px;
    top: -47px;
    right: 27.5px;
    z-index: 10;
    padding-left: 0!important;
    padding-right: 0!important;
}

.faqImageDivMo {
    position: relative;
    margin-top: 64px;
    margin-bottom: 32px;
}
.faqMainDiv {
    width: 1050px;
}

.faqMo {
    margin-bottom: 64px;
}