.service-customer-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width:100%;
    background-color: #FAFAFA;
    padding: 64px 0;
}

.service-customer-title {
    margin-bottom: 32px;
    width: 445px;
}

.service-customer-list-image {
    width: 928px;
}

.service-customer-container-mo {
    width:100%;
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    background-color: #FAFAFA;
}