.benefit-banner-wrapper {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 50;
}

.benefit-banner {
    position: relative;
    width: 1098px;
    padding: 20px 24px 20px 290px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.benefit-banner .title {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #000000;
}

.benefit-banner .title .logo-image {
    height: 18px;
    width: auto;
}

.benefit-banner .button-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.benefit-banner .button-wrapper .intro-download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11.5px 16px;
    background-color: black;
    gap: 4px;
    color: #FFFFFF;
    border-radius: 8px;
    height: 40px;

    font-size: 14px;
    line-height: 16.8px;
    font-weight: 700;

    cursor: pointer;
}

.benefit-banner .button-wrapper .intro-download-button .download-icon-image {
    width: 16px;
    height: 16px;
}

.benefit-banner .button-wrapper .orange-button {
    padding: 11.5px 36px;
    background-color: #FF5A00;
    border-radius: 8px;
    display: flex;
    align-items: center;
    height: 40px;
    color: #FFFFFF;

    font-size: 14px;
    line-height: 16.8px;
    font-weight: 700;
    cursor: pointer;
}

.benefit-banner .banner-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    opacity: 0.85;
    z-index: -1;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    backdrop-filter: blur(4px)
}

.benefit-banner .cafe25-image {
    position: absolute;
    bottom: 0;
    left: 69px;
    opacity: 1;
    width: 140px;
    height: 120px;
}

.benefit-banner-wrapper-mo {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    bottom: 0;
    z-index: 50;
}

.benefit-banner-mo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 24px 0;
}

.benefit-banner-mo .title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.benefit-banner-mo .title .first-line {
    display: flex;
    align-items: center;
    gap: 4px;
}

.benefit-banner-mo .title .first-line .logo-image-mo {
    width: 125px;
    height: 16px;
}

.benefit-banner-mo .banner-background-mo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    opacity: 0.85;
    z-index: -1;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    backdrop-filter: blur(4px)
}

.benefit-banner-mo .button-wrapper-mo {
    display: flex;
    gap: 8px;
    align-items: center;
}

.button-wrapper-mo .intro-download-button-mo {
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    color: #FFFFFF;

    background-color: #000000;
    border-radius: 8px;

    cursor: pointer;
}

.button-wrapper-mo .intro-download-button-mo .download-icon-image-mo {
    width: 16px;
    height: 16px;
}

.button-wrapper-mo .orange-button-mo {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #FF5A00;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    border-radius: 8px;
    cursor: pointer;
}

.delete-button {
    position: absolute;
    top: -20px;
    right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 32px;
    box-shadow: 0px 3px 6px 0px #0000004D;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
