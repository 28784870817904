.ui.dimmer {
    background-color: #00000088 !important;
}
.ui.disabled.input>input {
    background: #ECECEC;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 8px;
    color: black;
}

.ui.input>input {
    border-radius: 8px;
}

.ui.relaxed.grid {
    margin-right: 0px;
}
.ui.relaxed.grid>.column:not(.row) {
    padding: 0px;
}