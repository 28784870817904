
.mealbox-intro-container {
    background-color:#FAFAFA ;
    padding: 64px 0;
    width: 100%;
}

.mealbox-intro-layout {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
}

.mealbox-intro-title-img {
    width: 388px;
}

.intro-body-layout {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 840px;
}

.intro-body-img {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.no-cursor {
    cursor: inherit;
}

.intro-body-layout .top-text {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    align-self: flex-end;
}

.mealbox-intro-container-mo {
    padding: 64px 0 40px 0;
    background-color: #FAFAFA;
    width: 100%;
}

.mealbox-intro-layout-mo {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
}

.mealbox-intro-title-img-mo {
    width: 312px;
}

.intro-body-layout-mo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 312px;
}

.intro-body-layout-mo .top-text {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    align-self: flex-end;
}

