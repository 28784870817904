/* For PC */

#sticky {
    min-width: 100vw;
}

.gnb {
    display: flex;
    justify-content: center;
    min-width: 100vw;
    height: 82px;
    border: 0px;
    background-color: #FFFFFF;
    padding: 0 24px;
}
.gnb-frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 928px;
    width: 100%;
    height: 100%;
    border: 0;;
}
.gnb-logo {
    width: 181px;
    height: 22px;
    margin-left: 0
}

.gnb-frame-left {
    display: flex;
    align-items: center;
}

.gnb-frame-left-menu-buttonList {
    display: flex;
    align-items: center;
}

.top-menu-left {
    display: flex;
    align-items: center;
    gap: 16px;
}

.menu-button {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    cursor: pointer;
}

.dimmed-menu-button {
    font-size: 12px;
    line-height: 14px;
    color: #838383;
    cursor: pointer;
}

.orange-menu-button {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FF5A00;
    cursor: pointer;
}

.top-menu {
    display: flex;
    align-items: center;
    gap: 24px;

    /*width: 100%;*/
    /*margin-right: 0px;*/
}
.cnsl-btn {
    width: 120px !important;
    height: 40px !important;

    text-align: center !important;
    letter-spacing: -0.4px !important;

    border-radius: 8px !important;

    color: #FFFFFF;
}
.menu-item {
    margin-left: 22px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.4px;

    color: #000000;
}
.menu-item:hover {
    color: #000000;
    cursor: pointer;
}


/* For Mobile */
.gnb-mo {
    display: flex;
    width: 100%;/* 360px */
    height: 54px;
    border: 0px;
}
.gnb-logo-mo {
    width: 138px;
    height: 18px;
    margin-left: 4.44%;
    margin-top: 18px;
}
.top-menu-mo {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    margin-right: 14px;
    padding: 0px;
}
.menu-btn-mo {
    width: 32px;
    height: 32px;
    padding: 6px;
}
.menu-btn-mo:hover {
    cursor: pointer;
}
.cnsl-btn-mo {
    width: 90px;
    height: 32px;
    margin-right: 14px;

    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    /* identical to box height, or 150% */

    text-align: center !important;
    padding: 7px 0px !important;
    letter-spacing: -0.4px !important;

    color: #FFFFFF;
}
.dim-layer {
    display: none;
    position: fixed;
    _position:absolute;
    top: 54px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}
.dim-layer .dimBg {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    filter: alpha(opacity=50);
}
.dim-layer .pop-layer {
    display: block;
}
.drop-down-menu {
    position: relative;
    display: inline-block;
}
.dropdown-content {
    display: none;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0px 0px;
    z-index: 10;
}
.dropdown-item {
    height: 56px;
    padding: 18px;


    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    letter-spacing: -0.4px;
    /* Text+Iocn/Primary */
    color: #191923;
    cursor: pointer;
}

.customer-modal-container {
    position: relative;
    z-index: 999;

}

.customer-modal-dimmer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #00000066;
}

.customer-modal-layout {
    position: fixed;
    left: 50%;
    top: 80px;
    transform: translateX(-50%);
    background: #ffffff;
    border-radius: 8px;
    padding: 12px 24px;
    width: 380px;
    display: flex;
    flex-direction: column;
}

.customer-modal-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.customer-modal-body {
    display: flex;
    align-items: center;
    gap: 5.74px;
    justify-content: center;
    margin-top: 35px;
}

.customer-modal-body-text {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 3px;
}

.customer-modal-bottom-button {
    margin-top: 19px;
    align-self: flex-end;
    border-radius: 6px;
    border: 1px solid #535D911F;
    padding: 11px 12px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    width: 86px;
    cursor: pointer;
}

.customer-menu {
    width: 100vw;
    height: 56px;
    background-color: #F2F2F2;

    display: flex;
    justify-content: center;
}

.customer-menu-item {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.customer-menu-item-button {
    width: 100%;
    height: 100%;
    padding: 18px 4px;
    margin-left: 0.3rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: black;
}

.menu-right-wrapper-mo {
    display: flex;
    align-content: center;
    white-space: nowrap;
}

.gnb-mo-wrapper {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 8px 16px 16px;
}

.gnb-mo-right-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    overflow: hidden;
    white-space: nowrap;
}

@media (max-width: 767px) {
    .customer-menu-item {
        margin: 0 1em;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .customer-menu-item {
        width: 723px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .customer-menu-item {
        width: 933px;
    }
}

@media (max-width: 300px) {
    .mealbox-icon-mo {
        width: 120px;
    }
}

