html, body {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
  "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
  "Malgun Gothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-x pan-y;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/Pretendard-Regular.6090e6b5.woff2) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-style: normal;
  src: url(/static/media/Pretendard-Medium.ea235f1e.woff2) format('woff2');
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-style: normal;
  src: url(/static/media/Pretendard-SemiBold.7e5eaaf6.woff2) format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-style: normal;
  src: url(/static/media/Pretendard-Bold.0676c883.woff2) format('woff2');
}

.faq, .faqMo {
    display: flex;
    flex-direction: column;
    align-items: center;

    width:100%;

}
.faqImageDiv {
    position: relative;
    margin-top: 64px;
    margin-bottom: 32px;

    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.faq-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 4px;
}

.faq-title-mo {
    width: 312px;

}

.image-moo-moo {
    position: absolute;
    width: 155px;
    top: -50px;
    right: -220px;
    z-index: 10;
}

.image-moo-moo-mo {
    position: absolute;
    width: 77.5px;
    top: -47px;
    right: 27.5px;
    z-index: 10;
    padding-left: 0!important;
    padding-right: 0!important;
}

.faqImageDivMo {
    position: relative;
    margin-top: 64px;
    margin-bottom: 32px;
}
.faqMainDiv {
    width: 1050px;
}

.faqMo {
    margin-bottom: 64px;
}
th {
    font-weight: 500 !important;
    padding-bottom: 8px !important;
    font-size: 16px;
    line-height: 28px;
}

input::placeholder, textarea::placeholder {
    color: #00000061 !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    font-weight: 400;
    opacity: 1;
}

input, textarea {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
}

.onlineRequest {
    display: flex;
    flex-direction: column;
    align-items: center;

    width:100%;
    height: auto;
    border: 0px;

    background: #FAFAFA;
}
.onlineRequestMo {
    width:100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FAFAFA;
}
.titleDiv {
    position: relative;
    width: 360px;
    height: auto;
    margin-top: 64px;
    margin-bottom: 48px;
}
.titleDivMo {
    width: 312px;
    margin-top: 60px;

}
.info {
    font-family: Noto Sans KR;
    font-style: normal;
    font-size: 15px;
    line-height: 50px;
    color: #000000;
    text-align: center;
}
.info-mo {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 8px;
    width: 80%;

    font-family: Noto Sans KR;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    text-align: center;

    align-items: center;
}
.optionMealType, .optionPersonnelType {
    margin-top: 24px;
    margin-right: 20px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */


    letter-spacing: -0.4px;
    color: #000000;
}
.optionUnitBudget, .optionShipType {
    margin-right: 16px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */

    letter-spacing: -0.4px;
    color: #000000;
}
.optionRcptDays {
    margin-right: 18px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */

    align-items: center;
    letter-spacing: -0.4px;
    color: #000000;
}
.optionSubscribeTerms {
    margin-right: 17px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */

    color: #000000;
}

.table-div {
    margin-top: 44px;
    width: 100%;
}
.table-thMo {
    text-align: left;
    padding: 0 16px 0 16px;
}
.thOptionMo {
    padding: 0 16px 0 16px;
}
.thOptionMo2 {
    padding: 0 4px 0 16px;
}
.thOption3 {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    align-items: flex-end;
    text-align: right;
    color: rgba(0, 0, 0, 0.5);
}
.thOptionMo3 {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    align-items: flex-end;
    text-align: right;
    padding: 0 16px 0 16px;
    color: rgba(0, 0, 0, 0.5);
}
.optionsTdMo {
    width: 100%;
    padding: 8px 16px 24px 16px;
}
textarea::placeholder {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: -0.4px;
    color: rgba(0, 0, 0, 0.38);
}
.personalCheckDiv {
    width: 100%;
    padding: 0 16px 0 16px;
}
.personalCheckLabel {
    width: 100%;
    margin-left: 8px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    letter-spacing: -0.4px;
    color: #000000;
    text-align: left;
}
.submitButton {
    border: 1px solid #FF5A00;
    background: #FF5A00;
    border-radius: 8px;
}
.submitButtonDisabled {

    border: 1px solid #9E9E9E;
    background-color: #9E9E9E;
    border-radius: 8px;
}

.mobileOptionTable{
    width: 100%;
    padding: 16px 16px 0 16px;
}
.mobileOptionTable2 {
    width: 100%;
    padding: 16px 16px 16px 16px;
}

.ui.dimmer {
    background-color: #00000088 !important;
}
.ui.disabled.input>input {
    background: #ECECEC;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 8px;
    color: black;
}

.ui.input>input {
    border-radius: 8px;
}

.ui.relaxed.grid {
    margin-right: 0px;
}
.ui.relaxed.grid>.column:not(.row) {
    padding: 0px;
}
/* For PC */

#sticky {
    min-width: 100vw;
}

.gnb {
    display: flex;
    justify-content: center;
    min-width: 100vw;
    height: 82px;
    border: 0px;
    background-color: #FFFFFF;
    padding: 0 24px;
}
.gnb-frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 928px;
    width: 100%;
    height: 100%;
    border: 0;;
}
.gnb-logo {
    width: 181px;
    height: 22px;
    margin-left: 0
}

.gnb-frame-left {
    display: flex;
    align-items: center;
}

.gnb-frame-left-menu-buttonList {
    display: flex;
    align-items: center;
}

.top-menu-left {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.menu-button {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    cursor: pointer;
}

.dimmed-menu-button {
    font-size: 12px;
    line-height: 14px;
    color: #838383;
    cursor: pointer;
}

.orange-menu-button {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FF5A00;
    cursor: pointer;
}

.top-menu {
    display: flex;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;

    /*width: 100%;*/
    /*margin-right: 0px;*/
}
.cnsl-btn {
    width: 120px !important;
    height: 40px !important;

    text-align: center !important;
    letter-spacing: -0.4px !important;

    border-radius: 8px !important;

    color: #FFFFFF;
}
.menu-item {
    margin-left: 22px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.4px;

    color: #000000;
}
.menu-item:hover {
    color: #000000;
    cursor: pointer;
}


/* For Mobile */
.gnb-mo {
    display: flex;
    width: 100%;/* 360px */
    height: 54px;
    border: 0px;
}
.gnb-logo-mo {
    width: 138px;
    height: 18px;
    margin-left: 4.44%;
    margin-top: 18px;
}
.top-menu-mo {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    margin-right: 14px;
    padding: 0px;
}
.menu-btn-mo {
    width: 32px;
    height: 32px;
    padding: 6px;
}
.menu-btn-mo:hover {
    cursor: pointer;
}
.cnsl-btn-mo {
    width: 90px;
    height: 32px;
    margin-right: 14px;

    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    /* identical to box height, or 150% */

    text-align: center !important;
    padding: 7px 0px !important;
    letter-spacing: -0.4px !important;

    color: #FFFFFF;
}
.dim-layer {
    display: none;
    position: fixed;
    _position:absolute;
    top: 54px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}
.dim-layer .dimBg {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    filter: alpha(opacity=50);
}
.dim-layer .pop-layer {
    display: block;
}
.drop-down-menu {
    position: relative;
    display: inline-block;
}
.dropdown-content {
    display: none;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0px 0px;
    z-index: 10;
}
.dropdown-item {
    height: 56px;
    padding: 18px;


    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    letter-spacing: -0.4px;
    /* Text+Iocn/Primary */
    color: #191923;
    cursor: pointer;
}

.customer-modal-container {
    position: relative;
    z-index: 999;

}

.customer-modal-dimmer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #00000066;
}

.customer-modal-layout {
    position: fixed;
    left: 50%;
    top: 80px;
    transform: translateX(-50%);
    background: #ffffff;
    border-radius: 8px;
    padding: 12px 24px;
    width: 380px;
    display: flex;
    flex-direction: column;
}

.customer-modal-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.customer-modal-body {
    display: flex;
    align-items: center;
    grid-gap: 5.74px;
    gap: 5.74px;
    justify-content: center;
    margin-top: 35px;
}

.customer-modal-body-text {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 3px;
}

.customer-modal-bottom-button {
    margin-top: 19px;
    align-self: flex-end;
    border-radius: 6px;
    border: 1px solid #535D911F;
    padding: 11px 12px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    width: 86px;
    cursor: pointer;
}

.customer-menu {
    width: 100vw;
    height: 56px;
    background-color: #F2F2F2;

    display: flex;
    justify-content: center;
}

.customer-menu-item {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.customer-menu-item-button {
    width: 100%;
    height: 100%;
    padding: 18px 4px;
    margin-left: 0.3rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: black;
}

.menu-right-wrapper-mo {
    display: flex;
    align-content: center;
    white-space: nowrap;
}

.gnb-mo-wrapper {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 8px 16px 16px;
}

.gnb-mo-right-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    overflow: hidden;
    white-space: nowrap;
}

@media (max-width: 767px) {
    .customer-menu-item {
        margin: 0 1em;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .customer-menu-item {
        width: 723px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .customer-menu-item {
        width: 933px;
    }
}

@media (max-width: 300px) {
    .mealbox-icon-mo {
        width: 120px;
    }
}


/* For PC */
.benefit {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
}

.benefit-img {
    margin: 64px 0;
    width: 840px;
}

.moo-moo-heart-img {
    width: 140px;
    height: 140px;
    position: absolute;
    bottom: 0;
    left: 50%; /* 부모 요소의 가로 중심 */
    transform: translateX(-50%) translateX(510px);
}

/* For Mobile */
.benefit-mo {
    display: flex;
    justify-content: center;
    width: 100%;
}

.benefit-img-mo {
    width: 312px;
    margin: 64px 0;
}
.service-customer-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width:100%;
    background-color: #FAFAFA;
    padding: 64px 0;
}

.service-customer-title {
    margin-bottom: 32px;
    width: 445px;
}

.service-customer-list-image {
    width: 928px;
}

.service-customer-container-mo {
    width:100%;
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 32px;
    gap: 32px;
    background-color: #FAFAFA;
}
/* For PC */
.footer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 339px;
    border: 0px;
    background: #FFFFFF;
}
.footer .frame {
    display: flex;
    flex-direction: row;
    width: 928px;
    height: 240px;
    border: 0px;
    background: #FFFFFF;
}
.footer .logo {
    margin-top: 56px;
    margin-left: 0px;
    left: 50%;
    width: 168px;
}

.content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
    margin-left: 66px;
}

.footer .content {
    float: left;
    width: 386px;
}

.footer .service-customer {
    display: flex;
    flex-direction: column;
    float: left;
}

.footer .service {

}

.footer .footer-customer {
    display: flex;
    margin-top: 32px;
}

.footer .item {
    display: block;
    margin: 6px 0px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 312% */

    letter-spacing: -0.4px;

    color: rgba(0, 0, 0, 0.7);
}

.footer .itemMail {
    display: block;
    /*margin: 6px;*/

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 312% */

    letter-spacing: -0.4px;
    color: #FA4614;
}
.footer a {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
}

.vl {
    height: 105px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

/* For PC */
.footer-mo {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    border: 0px;
    background: #FFFFFF;
}
.footer-mo .content {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 87px;
    width: 328px;
}
.footer-mo .logo {
    margin-bottom: 31px;
}
.footer-mo .item {
    margin: 6px 0px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    /* or 312% */

    letter-spacing: -0.4px;

    color: rgba(0, 0, 0, 0.7);
}
.footer-mo .serviceMo {
    width: 328px;
    margin-top: 30px;
    margin-bottom: 47px;
}
.itemServiceMo {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
}
.itemMailMo {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: #FA4614;
}

.itemPhoneMo {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: #FA4614;
}

.footer-mo a {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
}
.serviceBanner-container {
    min-width: 1920px;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    position: relative;
}

.service-swiper {
    width: 100%;
    position: relative;
}

.service-swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
    line-height: 0;
}

.service-swiper > div {
    display: flex !important;
}

.banner-img {
    display: block;
    max-width: none !important;
    min-width: 1920px !important;
    width: 100vw;
    height: auto;
}

.navigation-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 64px;
    height: 64px;

    border-radius: 42.67px;
    box-shadow: 0px 4px 4px 0px #00000040;
    background-color: #FFFFFF;

    cursor: pointer;
}

.arrow-image-right {
    transform: rotate(180deg);
}

.navigation-button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 430px;
    right: 430px;
    transform: translateY(-50%);
}

.benefit-banner-wrapper {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 50;
}

.benefit-banner {
    position: relative;
    width: 1098px;
    padding: 20px 24px 20px 290px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.benefit-banner .title {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #000000;
}

.benefit-banner .title .logo-image {
    height: 18px;
    width: auto;
}

.benefit-banner .button-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.benefit-banner .button-wrapper .intro-download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11.5px 16px;
    background-color: black;
    grid-gap: 4px;
    gap: 4px;
    color: #FFFFFF;
    border-radius: 8px;
    height: 40px;

    font-size: 14px;
    line-height: 16.8px;
    font-weight: 700;

    cursor: pointer;
}

.benefit-banner .button-wrapper .intro-download-button .download-icon-image {
    width: 16px;
    height: 16px;
}

.benefit-banner .button-wrapper .orange-button {
    padding: 11.5px 36px;
    background-color: #FF5A00;
    border-radius: 8px;
    display: flex;
    align-items: center;
    height: 40px;
    color: #FFFFFF;

    font-size: 14px;
    line-height: 16.8px;
    font-weight: 700;
    cursor: pointer;
}

.benefit-banner .banner-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    opacity: 0.85;
    z-index: -1;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px)
}

.benefit-banner .cafe25-image {
    position: absolute;
    bottom: 0;
    left: 69px;
    opacity: 1;
    width: 140px;
    height: 120px;
}


.serviceBanner-container-mo {
    max-width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    position: relative;
}

.service-swiper-mo {
    min-width: 100vw;
}

.service-swiper-mo > div {
    display: flex !important;
}

.service-swiper-slide-mo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
    line-height: 0;
    min-width: 100vw !important;
}

.banner-img-mo {
    width: 100%;
    height: auto;
}

.navigation-button-mo {
    width: 48px;
    height: 48px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
}

.navigation-button-mo.left{
    position: absolute;
    left: 16px;
    top: 40%;
    transform: translateY(-50%);
}

.navigation-button-mo.right{
    position: absolute;
    right: 16px;
    top: 40%;
    transform: translateY(-50%);
}

.navigation-button-mo img {
    width: 32px;
    height: 32px;
}

.benefit-banner-wrapper-mo {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 50;
}

.benefit-banner-mo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    padding: 24px 0;
}

.benefit-banner-mo .title {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.benefit-banner-mo .title .first-line {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.benefit-banner-mo .title .first-line .logo-image-mo {
    width: 125px;
    height: 16px;
}

.benefit-banner-mo .banner-background-mo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    opacity: 0.85;
    z-index: -1;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px)
}

.benefit-banner-mo .button-wrapper-mo {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.button-wrapper-mo .intro-download-button-mo {
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;

    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    color: #FFFFFF;

    background-color: #000000;
    border-radius: 8px;

    cursor: pointer;
}

.button-wrapper-mo .intro-download-button-mo .download-icon-image-mo {
    width: 16px;
    height: 16px;
}

.button-wrapper-mo .orange-button-mo {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #FF5A00;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    border-radius: 8px;
    cursor: pointer;
}

.delete-button {
    position: absolute;
    top: -20px;
    right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 32px;
    box-shadow: 0px 3px 6px 0px #0000004D;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}


.mealbox-intro-container {
    background-color:#FAFAFA ;
    padding: 64px 0;
    width: 100%;
}

.mealbox-intro-layout {
    display: flex;
    flex-direction: column;
    grid-gap: 48px;
    gap: 48px;
    align-items: center;
}

.mealbox-intro-title-img {
    width: 388px;
}

.intro-body-layout {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    width: 840px;
}

.intro-body-img {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.no-cursor {
    cursor: inherit;
}

.intro-body-layout .top-text {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    align-self: flex-end;
}

.mealbox-intro-container-mo {
    padding: 64px 0 40px 0;
    background-color: #FAFAFA;
    width: 100%;
}

.mealbox-intro-layout-mo {
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
    align-items: center;
}

.mealbox-intro-title-img-mo {
    width: 312px;
}

.intro-body-layout-mo {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    width: 312px;
}

.intro-body-layout-mo .top-text {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    align-self: flex-end;
}




.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    border-radius: 8px;
    width: 620px;
    background-color: #FFFFFF;

    z-index: 1000;
}

.modal-container-mo {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 998;
}

.modal-layout-mo {
    width: 100%;
    z-index: 1000;
    background-color: #FFFFFF;
    height: 100vh; /* Full screen height minus 64px */
    max-height: 100vh; /* Prevents exceeding the calculated height */
    overflow: hidden;
    border-radius: 8px !important;
}

.modal-dimmed-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.53);
    z-index: 999;
}

.modal-header {
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 0 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.modal-header .modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.modal-header .modal-exit-button {
    padding: 6px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-body {
    overflow-y: auto;
    max-height: 828px;
}

.modal-body-mo {
    overflow-y: auto;
    height: calc(100% - 72px);
}

.modal-image-wrapper {
    width: 100%;
}

.modal-image {
    width: 100%;
}

.modal-bottom {
    padding: 16px 24px 32px 24px;
    border-top: 1px solid #DDDDDD;
    background-color: #F5F5F5;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

.bottom-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.bottom-description-list {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
}

.bottom-description {
    position: relative;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #333333;
}

.bottom-description::before {
    position: absolute;
    top: 0px;
    left: -12px;
    content: "·";
    font-size: 16px;
    line-height: 20px;
    margin-right: 4px;
    font-weight: bold;
}




.middle-banner-img {
    width: 100%;
    height: auto;
}

.review-banner-container {
    padding: 64px 0 64px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #FAFAFA;
}

.review-banner-layout {
    max-width: 842px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
}

.review-banner-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.review-banner-top-left {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.review-banner-top-right {
    display: flex;
    align-items: center;
}

.swiper-button {
    background-color: #E1E1E1;
    border-radius: 48px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.swiper-button.right {
    transform: rotate(180deg);
}

.swiper-page-number-layout {
    padding: 0 9.5px;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.swiper-page-number.current {
    font-weight: 700;
    font-size: 16px;
    line-height: 19.2px;
    color: #FF5A00;
    width: 24px;
    text-align: center;
}

.swiper-page-number.whole {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    color: #111111;
    width: 24px;
    text-align: center;
}

.logo-image {
    width: 204px;
    height: 26px;
}

.review-banner-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
}

.review-swiper > div, .review-swiper-mo > div {
    display: flex !important;
}

.review-swiper-mo {
    width: calc(100vw - 48px);
}

.review-swiper-slide, .review-swiper-slide-mo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
    line-height: 0;
}

.review-banner-img {
    width: 842px;
}

.review-banner-img-mo {
    width: calc(100vw - 48px);
}

.review-banner-container-mo {
    padding: 64px 0;
    background-color: #FAFAFA;
    width: 100%;
    display: flex;
    justify-content: center;
}

.review-banner-layout-mo {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 32px;
    gap: 32px;
    width: 312px;
}

.review-banner-top-mo {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.logo-img-mo {
    width: 214px;
}

.review-banner-title-mo {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
}

.review-banner-body-mo {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
}

.review-banner-pagination {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.swiper-button-mo {
    width: 48px;
    height: 48px;
    border-radius: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E1E1E1EE;
}

.swiper-button-mo.right {
    transform: rotate(180deg);
}

.swiper-page-number-layout-mo {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.swiper-page-number-mo.current {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FF5A00;
}

.swiper-page-number-mo.whole {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #111111;
}

.slash {
    font-weight: 400;
    font-size: 12px;
    line-height: 14.4px;
}

.slash-mo {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}








.subscription-intro-container {
    display: flex;
    justify-content: center;
    padding: 64px 0;
}

.subscription-img {
    width: 840px;
}

.subscription-intro-container-mo {
    display: flex;
    justify-content: center;
    padding: 64px 0;
}

.subscription-img-mo {
    width: 312px;
}
.benefit-banner-wrapper {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 50;
}

.benefit-banner {
    position: relative;
    width: 1098px;
    padding: 20px 24px 20px 290px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.benefit-banner .title {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #000000;
}

.benefit-banner .title .logo-image {
    height: 18px;
    width: auto;
}

.benefit-banner .button-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.benefit-banner .button-wrapper .intro-download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11.5px 16px;
    background-color: black;
    grid-gap: 4px;
    gap: 4px;
    color: #FFFFFF;
    border-radius: 8px;
    height: 40px;

    font-size: 14px;
    line-height: 16.8px;
    font-weight: 700;

    cursor: pointer;
}

.benefit-banner .button-wrapper .intro-download-button .download-icon-image {
    width: 16px;
    height: 16px;
}

.benefit-banner .button-wrapper .orange-button {
    padding: 11.5px 36px;
    background-color: #FF5A00;
    border-radius: 8px;
    display: flex;
    align-items: center;
    height: 40px;
    color: #FFFFFF;

    font-size: 14px;
    line-height: 16.8px;
    font-weight: 700;
    cursor: pointer;
}

.benefit-banner .banner-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    opacity: 0.85;
    z-index: -1;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px)
}

.benefit-banner .cafe25-image {
    position: absolute;
    bottom: 0;
    left: 69px;
    opacity: 1;
    width: 140px;
    height: 120px;
}

.benefit-banner-wrapper-mo {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    bottom: 0;
    z-index: 50;
}

.benefit-banner-mo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    padding: 24px 0;
}

.benefit-banner-mo .title {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.benefit-banner-mo .title .first-line {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.benefit-banner-mo .title .first-line .logo-image-mo {
    width: 125px;
    height: 16px;
}

.benefit-banner-mo .banner-background-mo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    opacity: 0.85;
    z-index: -1;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px)
}

.benefit-banner-mo .button-wrapper-mo {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.button-wrapper-mo .intro-download-button-mo {
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;

    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    color: #FFFFFF;

    background-color: #000000;
    border-radius: 8px;

    cursor: pointer;
}

.button-wrapper-mo .intro-download-button-mo .download-icon-image-mo {
    width: 16px;
    height: 16px;
}

.button-wrapper-mo .orange-button-mo {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #FF5A00;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    border-radius: 8px;
    cursor: pointer;
}

.delete-button {
    position: absolute;
    top: -20px;
    right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 32px;
    box-shadow: 0px 3px 6px 0px #0000004D;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

