/* For PC */
.footer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 339px;
    border: 0px;
    background: #FFFFFF;
}
.footer .frame {
    display: flex;
    flex-direction: row;
    width: 928px;
    height: 240px;
    border: 0px;
    background: #FFFFFF;
}
.footer .logo {
    margin-top: 56px;
    margin-left: 0px;
    left: 50%;
    width: 168px;
}

.content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
    margin-left: 66px;
}

.footer .content {
    float: left;
    width: 386px;
}

.footer .service-customer {
    display: flex;
    flex-direction: column;
    float: left;
}

.footer .service {

}

.footer .footer-customer {
    display: flex;
    margin-top: 32px;
}

.footer .item {
    display: block;
    margin: 6px 0px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 312% */

    letter-spacing: -0.4px;

    color: rgba(0, 0, 0, 0.7);
}

.footer .itemMail {
    display: block;
    /*margin: 6px;*/

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 312% */

    letter-spacing: -0.4px;
    color: #FA4614;
}
.footer a {
    text-decoration-line: underline;
    cursor: pointer;
}

.vl {
    height: 105px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

/* For PC */
.footer-mo {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    border: 0px;
    background: #FFFFFF;
}
.footer-mo .content {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 87px;
    width: 328px;
}
.footer-mo .logo {
    margin-bottom: 31px;
}
.footer-mo .item {
    margin: 6px 0px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    /* or 312% */

    letter-spacing: -0.4px;

    color: rgba(0, 0, 0, 0.7);
}
.footer-mo .serviceMo {
    width: 328px;
    margin-top: 30px;
    margin-bottom: 47px;
}
.itemServiceMo {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
}
.itemMailMo {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: #FA4614;
}

.itemPhoneMo {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: #FA4614;
}

.footer-mo a {
    text-decoration-line: underline;
    cursor: pointer;
}